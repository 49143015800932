import { graphql, Link, PageProps } from 'gatsby';
import * as React from 'react';

import { View } from '../components/view';
import { HomePageQuery } from '../generated/graphql';
import { Routes } from '../utils/routes';

export interface HomeViewProps extends PageProps<HomePageQuery> {}

export const HomeView: React.FC<HomeViewProps> = ({ data }) => {
  return (
    <View className="home-view" hideFooterLine>
      <ul>
        {data.allPrismicExhibition?.edges.map((exhibition) => (
          <li key={exhibition.node.id}>
            <Link to={Routes.exhibition(exhibition.node.data.name || '')}>{exhibition.node.data.name}</Link>
          </li>
        ))}
      </ul>
    </View>
  );
};

export default HomeView;

export const pageQuery = graphql`
  query HomePage {
    allPrismicExhibition(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          id
          data {
            name
            date
          }
        }
      }
    }
  }
`;
